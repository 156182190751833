import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Tag from "../components/tag"
import { css } from "@emotion/react"

export default function BlogPost({data}) {
    const post = data.markdownRemark
    return (
        <Layout>
            <div>
                <h2 class="accent accent-padding">{post.frontmatter.title}</h2>
                <p>
                    Rédigé le {post.frontmatter.date} 
                    <span css={css`float: right;`}>
                    {post.frontmatter.tags.map(tag => (
                        <Tag tag={tag} />
                    ))}
                    </span>
                </p>
                <hr/>
                <div dangerouslySetInnerHTML={{ __html: post.html }} />
            </div>
        </Layout>
    )
}

export const query = graphql`
    query($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
                date(formatString: "DD-MM-YYYY à HH:mm")
                tags
            }
        }
    }
`