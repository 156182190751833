import React from "react"
import {Link} from "gatsby"
import _ from "lodash"

export default function Tag(props) {
  return (
    <Link class="badge badge-dark" to={`/tags/${_.kebabCase(props.tag)}`}>
      {props.tag}
    </Link>
  )
}